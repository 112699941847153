import { tv, VariantProps } from '@/utils/tv'

export const heroCenteredParallax = tv({
  slots: {
    base: [
      'relative',
      'md:pt-24 md:pb-16',
      'overflow-hidden',
      'md:h-[700px]',
      'md:before:content-[""] md:after:content-[""]',
      'md:before:pointer-events-none md:after:pointer-events-none',
      'md:before:absolute md:after:absolute',
      'md:before:z-30 md:after:z-30',
      'md:before:left-0 md:after:left-0',
      'md:before:top-0 md:after:bottom-0',
      'md:before:h-32 md:after:h-32',
      'md:before:w-full md:after:w-full',
      'md:before:bg-gradient-to-b md:before:from-black',
      'md:after:bg-gradient-to-t md:after:from-black'
    ],
    wrapper: [
      'max-w-7xl',
      'h-full',
      'mx-auto',
      'px-6 lg:px-8',
      'relative z-20',
      'flex items-center'
    ],
    caption: ['relative z-30', 'pt-24 pb-12 md:py-0', 'w-full'],
    preTitle: ['mb-2'],
    title: ['drop-shadow-lg break-keep'],
    description: ['mt-4', 'max-w-xl'],
    buttonGroup: ['mt-8'],
    image: ['aspect-w-1 aspect-h-1']
  },
  variants: {
    theme: {
      dark: {
        base: 'bg-black',
        preTitle: 'text-acqua-500',
        description: 'text-gray-40'
      },
      light: {
        base: 'bg-white',
        preTitle: 'text-acqua-900',
        description: 'text-gray-60'
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export const heroCenteredParallaxVideos = tv({
  slots: {
    base: [
      'w-3/4 lg:w-1/2',
      'absolute rtl:left-0 ltr:-right-10 inset-y-0 z-10',
      'my-auto',
      'grid grid-cols-2 gap-3 items-center',
      'opacity-25 lg:opacity-50'
    ],
    group: [
      'flex flex-col justify-center gap-3',
      'h-full',
      'first-of-type:mt-20 last-of-type:-mt-20'
    ]
  }
})

export type HeroCenteredParallaxVariantProps = VariantProps<
  typeof heroCenteredParallax
>
