import { tv, VariantProps } from '@/utils/tv'

export const cigarBarVariant = tv({
  slots: {
    base: [
      'bg-black',
      'overflow-hidden',
      'before:pointer-events-none before:select-none',
      'before:absolute before:z-0 before:block before:h-full before:w-full',
      'before:bg-cigarbar',
      'before:inset-0 before:m-auto'
    ],
    wrapper: ['max-w-7xl', 'mx-auto', 'px-6 lg:px-8'],
    container: [
      'flex justify-between items-center md:items-center gap-4 md:min-h-[64px]',
      'relative z-20'
    ],
    image: ['hidden md:flex', 'w-auto h-11'],
    textWrapper: ['my-3 mr-auto'],
    text: ['[&>b]:font-semibold [&>span>b]:text-white'],
    action: ['shrink-0 my-auto md:my-0']
  }
})

export type CigarBarVariantProps = VariantProps<typeof cigarBarVariant>
