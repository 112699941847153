'use client'

import { Button } from '../button'
import { Text } from '../text'
import NextImage from 'next/image'
import { useCigarBar, useCigarBarProps } from './use-cigar-bar'

export type CigarBarProps = useCigarBarProps

const CigarBar = (props: CigarBarProps) => {
  const { ref, className, styles, data } = useCigarBar(props)

  return (
    <div ref={ref} className={styles.base({ className })}>
      {!!data && (
        <div className={styles.wrapper()}>
          <div className={styles.container()}>
            {!!data?.background && (
              <NextImage
                className={styles.image()}
                src={data?.background.url}
                width={data?.background.width}
                height={data?.background.height}
                alt={data?.background.alternativeText}
              />
            )}
            {!!data?.text && (
              <div className={styles.textWrapper()}>
                <Text
                  className={styles.text()}
                  size="cigarbar"
                  color="text-white"
                  weight="medium"
                >
                  {data.text}
                </Text>
              </div>
            )}
            {!!data?.link && (
              <Button
                size="xs"
                color="white"
                appearance="text"
                rightIcon="arrow_right"
                href={data.link.url}
                title={data.link.text}
                className={styles.action()}
              >
                {data.link.text}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CigarBar
