import NextImage from 'next/image'
import { Text } from '../text'
import { useQuoteCard, useQuoteCardProps } from './use-quote-card'

export type QuoteCardProps = useQuoteCardProps

const QuoteCard = (props: QuoteCardProps) => {
  const {
    className,
    styles,
    data: { name, role, description, avatar }
  } = useQuoteCard(props)

  return (
    <article
      data-component-name="quote-card"
      className={styles.base({ className })}
    >
      <div className={styles.background()}>
        <NextImage
          fill={true}
          width={0}
          height={0}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          src={avatar.url}
          alt={avatar.alternativeText}
          className={styles.image()}
        />
      </div>
      <div className={styles.caption()}>
        <div className={styles.body()}>
          <Text
            className={styles.description()}
            size="subtitle"
            weight="medium"
          >
            {description}
          </Text>
        </div>
        <footer className={styles.footer()}>
          <Text className={styles.name()} tag="cite" size="h6" weight="medium">
            {name}
          </Text>
          {!!role && (
            <Text
              className={styles.role()}
              tag="span"
              size="tag"
              weight="medium"
            >
              {role}
            </Text>
          )}
        </footer>
      </div>
    </article>
  )
}

export default QuoteCard
