import { useMemo } from 'react'
import { BlockProps, MediaCard } from '@/types'
import useUTMParams from '@/hooks/use-utm-params'
import { MediaCardVariantProps, mediaCard } from './media-card.variants'

export type useMediaCardProps = BlockProps<MediaCard, MediaCardVariantProps>

export const useMediaCard = (props: useMediaCardProps) => {
  const {
    className = '',
    theme = 'dark',
    appearance = 'default',
    layout = 'vertical',
    sectionName,
    data,
    ...componentProps
  } = props

  const { id, url, title, writer } = data

  const { appendUTMParams } = useUTMParams()

  const styles = useMemo(
    () => mediaCard({ theme, layout, appearance }),
    [theme, layout, appearance]
  )

  const isYoutubeVideo =
    !!url && (url.includes('youtube') || url.includes('youtu.be'))
  const isHorizontalLayout = layout === 'horizontal'

  const hasContent = !!data.preTitle || !!data.title || !!data.description
  const showCaption = hasContent || !!writer

  const getHrefWithUTM = () => {
    if (!url) return null
    if (isYoutubeVideo) return url

    return appendUTMParams(url, `${sectionName}_${id}`)
  }

  return {
    theme,
    className,
    sectionName,
    data: {
      ...data,
      title,
      url: getHrefWithUTM()
    },
    styles,
    appearance,
    isYoutubeVideo,
    isHorizontalLayout,
    showCaption,
    hasContent,
    componentProps
  }
}
