'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { ReviewCard } from '@/components/ui/review-card'
import { QuoteCard } from '@/components/ui/quote-card'
import { useReviewOrQuoteCarouselProps } from './use-review-or-quote-carousel'

export type ReviewOrQuoteCarouselSwiperProps = Pick<
  useReviewOrQuoteCarouselProps,
  'className' | 'sectionName' | 'data'
>

const cardAppearanceMap = {
  ghost: ReviewCard,
  card: ReviewCard,
  cardImage: QuoteCard,
  pro: QuoteCard
}

const ReviewOrQuoteCarouselSwiper = ({
  className = '',
  sectionName,
  data
}: ReviewOrQuoteCarouselSwiperProps) => {
  const { theme, cardAppearance, reviews = [], disableAutoplay = false } = data

  const ReviewComponent = cardAppearanceMap[cardAppearance]

  return (
    <Swiper
      className={className}
      modules={[Autoplay]}
      dir="ltr"
      simulateTouch={!!disableAutoplay ? true : false}
      spaceBetween={40}
      speed={800}
      slidesPerView={3}
      watchSlidesProgress
      autoplay={
        !!disableAutoplay
          ? false
          : {
              delay: 3000,
              pauseOnMouseEnter: true,
              disableOnInteraction: true
            }
      }
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1199: {
          slidesPerView: 3,
          spaceBetween: 24
        }
      }}
    >
      {reviews.map((review) => (
        <SwiperSlide key={`review-card-${review.id}`} className="!h-auto">
          <ReviewComponent
            sectionName={sectionName}
            theme={theme}
            cardAppearance={cardAppearance}
            data={review}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ReviewOrQuoteCarouselSwiper
