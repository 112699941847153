import slugifyLib from 'slugify'

const slugify = (content: string) => {
  if (content) {
    content = slugifyLib(content, {
      lower: true,
      strict: true,
      replacement: '_'
    })
  }

  return content
}

export { slugify }
