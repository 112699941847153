import React, { Children } from 'react'
import { useText, useTextProps } from './use-text'
import { Button } from '../button'

export type TextProps = useTextProps

const regexStrikethrough = /~(.*?)~/g
const regexHighlight = /\*\*(.*?)\*\*/g
const regexBold = /\*(.*?)\*/g
const regexLink = /\[([^\]]+)]\((\/[^\s)]*|https?:\/\/[^\s)]+)\)/g

const replaceWithMarkup = (str: string, theme = 'dark') => {
  const highlightColor = theme === 'dark' ? 'text-acqua-500' : 'text-acqua-900'

  const parts: (string | JSX.Element)[] = []
  let lastIndex = 0

  str.replace(regexLink, (match, text, url, offset) => {
    if (lastIndex < offset) {
      parts.push(str.slice(lastIndex, offset))
    }

    const isRelative = url.startsWith('/')

    parts.push(
      <Button
        key={`${text}-${url}`}
        href={url}
        appearance="text"
        className="text-[length:inherit]"
        newTab={!isRelative}
        color={theme === 'dark' ? 'acqua' : 'acquaDark'}
      >
        {text}
      </Button>
    )

    lastIndex = offset + match.length
    return match
  })

  if (lastIndex < str.length) {
    parts.push(str.slice(lastIndex))
  }

  return parts.flatMap((part, i) => {
    if (typeof part === 'string') {
      const subParts = part.split(/\\n/).flatMap((line, index, array) => {
        const lineParts: (string | JSX.Element)[] = []

        line = line.replace(regexStrikethrough, (_, content) => {
          lineParts.push(
            <s className="line-through" key={`strikethrough-${i}-${index}`}>
              {content}
            </s>
          )
          return ''
        })

        line = line.replace(regexHighlight, (_, content) => {
          lineParts.push(
            <span key={`highlight-${i}-${index}`} className={highlightColor}>
              {content}
            </span>
          )
          return ''
        })

        line = line.replace(regexBold, (_, content) => {
          lineParts.push(<b key={`bold-${i}-${index}`}>{content}</b>)
          return ''
        })

        if (line) lineParts.unshift(line)

        if (index < array.length - 1) {
          return [
            ...lineParts,
            <br key={`br-${i}-${index}`} className="md:block" />
          ]
        }

        return lineParts
      })

      return subParts
    }

    return part
  })
}

const Text = (props: TextProps) => {
  const {
    Component,
    className,
    children,
    theme,
    color,
    styles,
    componentProps
  } = useText(props)

  return (
    <Component
      className={styles.base({ className: `${className} ${color}` })}
      {...componentProps}
    >
      {Children.map(children, (child) => {
        if (
          typeof child === 'string' &&
          (child.includes('\\n') ||
            child.includes('*') ||
            child.includes('~') ||
            child.includes('['))
        ) {
          return replaceWithMarkup(child, theme)
        }

        return child
      })}
    </Component>
  )
}

export default Text
