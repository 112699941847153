import { tv, VariantProps } from '@/utils/tv'

export const videoCard = tv({
  slots: {
    base: [
      'group',
      'outline-none',
      'overflow-hidden cursor-pointer',
      'focus:outline-acqua-500'
    ],
    play: [
      'flex items-center justify-center',
      'group-hover:scale-125',
      'transition-all duration-300',
      'w-16 h-16',
      'rounded-full',
      'absolute z-10 inset-0',
      'm-auto'
    ],
    icon: ['w-5 h-5 text-black ml-1'],
    image: ['w-full h-full object-center object-cover'],
    video: ['aspect-h-9 aspect-w-16']
  },
  variants: {
    appearance: {
      default: {},
      pro: {
        base: ['aspect-w-9 aspect-h-12', 'outline-offset-0'],
        play: ['bg-gray-20']
      },
      tall: {}
    }
  },
  compoundVariants: [
    {
      appearance: ['default', 'tall'],
      className: {
        base: ['aspect-w-16 aspect-h-9', 'rounded-[20px]', 'outline-offset-2'],
        play: ['bg-white']
      }
    }
  ],
  defaultVariants: {
    appearance: 'default'
  }
})

export type VideoCardVariantProps = VariantProps<typeof videoCard>
