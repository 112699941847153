import { tv, VariantProps } from '@/utils/tv'

export const quoteCard = tv({
  slots: {
    base: ['relative', 'rounded-3xl', 'overflow-hidden', 'h-[640px]'],
    background: [
      'relative',
      'w-full h-full',
      'before:bg-gradient-to-b before:from-transparent before:to-black',
      'before:absolute before:inset-0 before:z-10'
    ],
    image: ['w-full h-full', 'object-center object-cover'],
    caption: ['absolute bottom-0 inset-x-0 z-20', 'p-6'],
    body: ['mb-6'],
    description: [],
    footer: ['flex flex-col gap-1'],
    name: ['not-italic'],
    role: []
  },
  variants: {
    theme: {
      dark: {
        base: ['text-white', 'border border-gray-600'],
        role: ['text-gray-60']
      },
      light: {
        base: ['text-black', 'border border-gray-60'],
        role: ['text-gray-60']
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export type QuoteCardVariantProps = VariantProps<typeof quoteCard>
