import NextImage from 'next/image'
import NextLink from 'next/link'
import { VideoCard } from '../video-card'
import { Media } from '@/types'
import { MediaCardVariantReturnProps } from './media-card.variants'

type MediaCardFigureProps = {
  styles: MediaCardVariantReturnProps
  title: string | null
  cover: Media
  url: string | null
  isYoutubeVideo: boolean
  appearance?: 'default' | 'pro' | 'tall'
}

const MediaCardFigure = (props: MediaCardFigureProps) => {
  const { styles, title, cover, isYoutubeVideo, url, appearance } = props

  if (url && isYoutubeVideo) {
    return (
      <VideoCard
        appearance={appearance}
        title={title}
        url={url}
        cover={cover}
      />
    )
  }

  if (!cover) return null

  const cardImage = (
    <NextImage
      fill
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      src={cover.url}
      alt={cover.alternativeText}
      className={styles.image()}
    />
  )

  if (url) {
    return (
      <NextLink
        href={url}
        title={title || undefined}
        className={styles.imageCard({
          className: 'outline-none outline-offset-2 focus:outline-acqua-500'
        })}
      >
        {cardImage}
      </NextLink>
    )
  }

  return <div className={styles.imageCard()}>{cardImage}</div>
}

export default MediaCardFigure
